import { encode, ParsedUrlQueryInput } from 'querystring';
import { CompanyAPIResult } from '../types/api/companyTypes';
import { PropertyGroupAPIResult } from '../types/api/propertyGroupTypes';
import { PropertyAPIResult } from '../types/api/propertyTypes';
import { LanguageKey } from '../types/common';
import { TRUSTED_DOMAINS } from './constants';
import { getDefaultLanguagePaths, getRoute } from './languages';
import { slugify } from './property';

export function getPropertyGroupLink(propertyGroup: PropertyGroupAPIResult, locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/property-group/[slug]${suffix}`, locale, getDefaultLanguagePaths(slugify(propertyGroup)));
}

export function createPropertyGroupLink(id: string, name: string, slug: string, locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/property-group/[slug]${suffix}`, locale, getDefaultLanguagePaths(slugify({ id, name, slug })));
}

export function createCompanyLink(id: string, name: string, slug: string, locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/company/[slug]${suffix}`, locale, getDefaultLanguagePaths(slugify({ id, name, slug })));
}

export function createPropertyLink(id: string, name: string, slug: string, locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/property/[slug]${suffix}`, locale, getDefaultLanguagePaths(slugify({ id, name, slug })));
}

export function getPropertyLink(property: PropertyAPIResult, locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/property/[slug]${suffix}`, locale, getDefaultLanguagePaths(slugify(property)));
}

export function getCompanyLink(company: CompanyAPIResult, locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/company/[slug]${suffix}`, locale, getDefaultLanguagePaths(slugify(company)));
}

export function getSearchLink(locale: LanguageKey): string {
  return getRoute('/search', locale);
}

export function getArticlesLink(locale: LanguageKey, categoryName?: string): string {
  return `${getRoute('/articles', locale)}${categoryName ? `?category=${categoryName}` : ''}`;
}

export function getAccountLink(locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/account${suffix}`, locale);
}

export function getOpenHouseLink(propertyGroup: PropertyGroupAPIResult, locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/property-group/[slug]/open-house${suffix}`, locale, getDefaultLanguagePaths(slugify(propertyGroup)));
}

/** Determines if a URL is from a trusted domain. */
export function isUrlTrustedDomain(url: string): boolean {
  const hostname = new URL(url).hostname;
  return TRUSTED_DOMAINS.some((domain) => hostname.match(`^${domain}$`));
}

/** Retrieves the query parameters from a `ParsedUrlQueryInput`. */
export function getQueryParamsFromUrl(parsedUrlQuery: ParsedUrlQueryInput): URLSearchParams {
  const urlQueryString = encode(parsedUrlQuery);
  return new URLSearchParams(urlQueryString);
}

export function getWaitingListLink(propertyGroup: PropertyGroupAPIResult, locale: LanguageKey, suffix: string = ''): string {
  return getRoute(`/property-group/[slug]/waiting-list${suffix}`, locale, getDefaultLanguagePaths(slugify(propertyGroup)));
}
