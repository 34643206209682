import { faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useState } from 'react';
import { userState } from '../../state/userState';
import { HeaderLoginCMSResult } from '../../types/cms/headerTypes';
import { LoginModalStatus } from '../../types/common';
import { Modal } from 'legacy-ui';
import CreateUser from './CreateUser';
import EnterCode from './EnterCode';
import EnterEmail from './EnterEmail';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import { useRouter } from 'next/router';

interface LoginProps {
  data?: HeaderLoginCMSResult;
}

const Login = ({ data }: LoginProps) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<LoginModalStatus>('enterEmail');
  const [{ userLoginEmail, loginModalStatus, showLoginPopup }, setUser] = useAtom(userState);
  const { isWhiteLabel } = useWhiteLabel();
  const router = useRouter();
  const preventModalClosure = isWhiteLabel && router.pathname === '/';

  useEffect(() => {
    if (userLoginEmail) {
      setEmail(userLoginEmail);
    }
  }, [userLoginEmail]);

  useEffect(() => {
    if (loginModalStatus === 'enterCode') {
      setStatus(loginModalStatus);
    }
  }, [loginModalStatus]);

  function closeModal() {
    setUser((value) => ({ ...value, userLoginEmail: '', loginModalStatus: 'enterEmail', showLoginPopup: false }));
    setEmail('');
    setStatus('enterEmail');
  }

  const onClickOutside = () => {
    if (preventModalClosure) {
      return;
    }
    closeModal();
  };

  return (
    <>
      <button onClick={() => setUser((value) => ({ ...value, showLoginPopup: true }))}>
        <FontAwesomeIcon icon={faLockAlt} />
        {data?.Label}
      </button>
      <Modal isOpen={!!showLoginPopup} closeModal={onClickOutside} isCloseButtonVisible={!preventModalClosure}>
        {status === 'enterEmail' && <EnterEmail data={data} email={email} setEmail={setEmail} setStatus={setStatus} />}
        {status === 'enterCode' && <EnterCode data={data} email={email} closeModal={closeModal} setStatus={setStatus} />}
        {status === 'createUser' && <CreateUser data={data} email={email} setEmail={setEmail} setStatus={setStatus} />}
      </Modal>
    </>
  );
};

export default Login;
