import styled from 'styled-components';
import { FooterCMSResult } from '../../types/cms/footerTypes';
import { HeaderCMSResult } from '../../types/cms/headerTypes';
import { ResourceLanguagePath } from '../../types/common';
import Footer, { FooterOptions } from './Footer';
import Header, { HeaderOptions } from './Header';

interface PageProps {
  header?: { options?: HeaderOptions; data?: HeaderCMSResult };
  footer?: {
    options?: FooterOptions;
    data?: FooterCMSResult;
    languagePaths?: ResourceLanguagePath[];
  };
  children: any;
  isGrid?: boolean;
}

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledPage = styled.main`
  flex: 1;
  padding-top: ${(props) => props.theme.style.styledPagePaddingTop};
  position: relative;
`;

function Page({ header, footer, children, isGrid }: PageProps) {
  return (
    <StyledLayout>
      <Header {...header} isGrid={isGrid} />
      <StyledPage>{children}</StyledPage>
      <Footer {...footer} />
    </StyledLayout>
  );
}

export default Page;
