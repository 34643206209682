import charmap from '../json/characters/charmap.json';
import {
  PropertyAPIResult,
  PropertyDetailsAPIResult,
  TransactionDetailsAPIResult,
  HouseholdUtility,
  HouseholdUtilityName,
} from '../types/api/propertyTypes';
import { GeneralCMSResult } from '../types/cms/generalTypes';
import { LanguageKey } from '../types/common';
import { kebabCase } from './common';
import { PROPERTY_RENTED, PROPERTY_TO_RENT, PROPERTY_TYPE_APARTMENT } from './constants';
import { formatAsCurrency } from './currency';
import { numberToOrdinal } from './format';
import { CompanyAPIResult } from '../types/api/companyTypes';
import { PropertyGroupAPIResult } from '../types/api/propertyGroupTypes';
import { Breadcrumb } from '../components/Breadcrumbs';
import { getCompanyLink, getPropertyGroupLink } from './links';

type CharMapCharacter = keyof typeof charmap;

export function getFloorOrLevelText(propertyDetails: PropertyDetailsAPIResult, generalTexts: GeneralCMSResult, locale: LanguageKey): string {
  const { type, floor, levels } = propertyDetails;
  if (type === PROPERTY_TYPE_APARTMENT) {
    return getFloorText(floor, generalTexts, locale);
  }
  if (levels === null) {
    return '-';
  }
  if (levels === 0 || levels === 1) {
    return `1 ${generalTexts.PropertyDetails.LevelSingular.toLowerCase()}`;
  }
  return `${levels} ${generalTexts.PropertyDetails.LevelPlural.toLowerCase()}`;
}

export function getFloorText(floor: number, generalTexts: GeneralCMSResult, locale: LanguageKey): string {
  if (floor === null) {
    return '-';
  }
  if (floor === 0) {
    return generalTexts.PropertyDetails.GroundFloor;
  }
  return `${numberToOrdinal(floor, locale)} ${generalTexts.PropertyDetails.FloorSingular.toLowerCase()}`;
}

export function getIdFromSlug(slug: string): string {
  const [id] = slug.split('-');
  return id;
}

export function slugify(entry: { name: string; id: string; slug: string }) {
  const { name, id, slug } = entry;
  const slugifiedName = slug || kebabCase(name);
  return `${id}-${slugifiedName}`;
}

export function getPrice(property: PropertyAPIResult, rentPerMonthText: string): string {
  const { transactionDetails, transactionStatus } = property;

  if (transactionStatus === PROPERTY_RENTED) {
    return '-';
  }

  //part of the decimal migration process
  if (transactionDetails.unit === 'cents') {
    return formatAsCurrency(transactionDetails.price, transactionDetails.unit);
  }

  return `${formatAsCurrency(transactionDetails.price, transactionDetails.unit)}
    ${transactionDetails.type === PROPERTY_TO_RENT ? rentPerMonthText : ''}`;
}

export function findUtility(name: HouseholdUtilityName, utilitiesNew: HouseholdUtility[]): HouseholdUtility | undefined {
  return utilitiesNew?.find((utility) => utility.name === name);
}

export function getUtilitiesTotal(transactionDetails: TransactionDetailsAPIResult): number {
  const utilitiesToSum = isUtilityActive('UTILITIES', transactionDetails)
    ? ['UTILITIES', 'ELECTRICITY', 'COOLING', 'INTERNET', 'RESIDENCE_REPRESENTATION', 'TV_ANTENNA']
    : ['HEATING', 'WATER', 'ELECTRICITY', 'COOLING', 'INTERNET', 'RESIDENCE_REPRESENTATION', 'TV_ANTENNA'];

  return sumUtilityAmounts(utilitiesToSum as HouseholdUtilityName[], transactionDetails);
}

function sumUtilityAmounts(utilityNames: HouseholdUtilityName[], transactionDetails: TransactionDetailsAPIResult): number {
  return utilityNames.map((name) => getUtilityAmount(name, transactionDetails) ?? 0).reduce((accumulator, amount) => accumulator + amount, 0);
}

export function isUtilityActive(name: HouseholdUtilityName, transactionDetails: TransactionDetailsAPIResult): boolean {
  return getUtilityAmount(name, transactionDetails) != null || getUtilityDirect(name, transactionDetails);
}

export function getUtilityAmount(name: HouseholdUtilityName, transactionDetails: TransactionDetailsAPIResult): number | undefined {
  const utility = findUtility(name, transactionDetails.utilitiesNew);
  return utility?.amount;
}

export function getUtilityDirect(name: HouseholdUtilityName, transactionDetails: TransactionDetailsAPIResult): boolean {
  return !!findUtility(name, transactionDetails.utilitiesNew)?.directPayment;
}
