import { validate } from 'email-validator';
import { FormEvent, useState } from 'react';
import { HeaderLoginCMSResult } from '../../types/cms/headerTypes';
import { LoginModalStatus } from '../../types/common';
import { apiPost } from '../../utils/http';
import { Button } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import { ModalButtons, ModalHeader, ModalTextField } from 'legacy-ui';
import { Text } from 'legacy-ui';
import { TextField } from 'legacy-ui';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import { useTranslation } from 'next-i18next';

interface EnterEmailProps {
  data?: HeaderLoginCMSResult;
  email: string;
  setEmail: (email: string) => void;
  setStatus: (status: LoginModalStatus) => void;
}

const EnterEmail = ({ data, email, setEmail, setStatus }: EnterEmailProps) => {
  const { t } = useTranslation('account');
  const [error, setError] = useState('');
  const { isWhiteLabel, hostname: whiteLabelConfigHostname } = useWhiteLabel();
  async function handleLogin(event: FormEvent) {
    event.preventDefault();
    if (!validate(email)) {
      setError(`${data?.InputEmailErrorValid}`);
      return;
    }

    const result = await apiPost('auth/login', { email, firstRequest: true, whiteLabelConfigHostname });

    if (result.status === 403) {
      setError(t('deactivatedAccount'));
      return;
    }

    const body = await result.text();
    if (typeof body === 'string' && body.length) {
      const resultMessage = JSON.parse(body).message;
      if (resultMessage === 'NO_ACCOUNT') {
        setStatus('createUser');
        return;
      }
    }

    setStatus('enterCode');
  }

  return (
    <>
      <ModalHeader>
        <Heading size="s" tag="h3">
          {data?.LogInHeadline}
        </Heading>
        {!isWhiteLabel && data?.LogInDescription && <Text>{data?.LogInDescription}</Text>}
      </ModalHeader>
      <form onSubmit={handleLogin} noValidate>
        <ModalTextField>
          <TextField
            autoFocus
            type="email"
            value={email}
            id="email"
            onChange={(event) => {
              const { value } = event.target;
              value && setError('');
              setEmail(value);
            }}
            placeholder={data?.InputEmailPlaceholder}
            label={data?.InputEmailLabel}
            autoComplete="email"
            error={error}
          />
        </ModalTextField>
        <ModalButtons>
          <div>
            <Button fullWidth color="primary" type="submit">
              {data?.LogInButton}
            </Button>
          </div>
        </ModalButtons>
      </form>
    </>
  );
};

export default EnterEmail;
