import { faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import CMSLinkItem from '../../components/CMSLinkItem';
import LanguageSelect from '../../components/LanguageSelect';
import { Text } from 'legacy-ui';
import { FooterCMSResult } from '../../types/cms/footerTypes';
import { ResourceLanguagePath } from '../../types/common';
import Container from './Container';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { DANSK } from '../../utils/languages';
import { isBelowBreakpoint } from 'styles/theme';
import { useGetWindowSize } from 'hooks/useGetWindowSize';
import PoweredByPropstepLogo from '../../assets/powered-by-propstep.svg';

export type FooterOptions = 'no-border' | undefined;

interface FooterProps {
  options?: FooterOptions;
  data?: FooterCMSResult;
  languagePaths?: ResourceLanguagePath[];
}

const FooterWrapper = styled.footer<FooterProps>`
  background-color: ${(props) => props.theme.color.footerBackgroundColor};
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.color.gray30};
  padding: ${(props) => props.theme.style.footerPaddingMobile};
  color: ${(props) => props.theme.color.footerTextColor};
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    text-align: left;
    padding: ${(props) => props.theme.style.footerPadding};
  }
  ${(props) => {
    if (props.options === 'no-border') {
      return `
        border-top: none;
    `;
    }
  }}
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterLinks = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: ${(props) => props.theme.style.footerLinksBreakpoint}) {
    flex-direction: row;
  }
  > li {
    margin-bottom: 16px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      margin: ${(props) => props.theme.style.footerLinksMargin};
    }
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
      margin-bottom: 0;
      margin-right: 32px;
    }
    > a {
      font-weight: 500;
      &:hover {
        color: ${(props) => props.theme.color.footerTextColorHover};
        text-decoration: underline;
      }
    }
    button {
      font-weight: 500;
      background: transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      > span {
        flex: 0 0 20px;
      }
      p {
        margin: 0 8px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FooterSocial = styled.ul`
  display: flex;
  margin-top: 32px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    margin-top: 0;
  }
  li {
    margin-left: 4px;
    margin-right: 4px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
      margin-left: 8px;
      margin-right: 0;
    }
    a {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: ${(props) => props.theme.color.gray20};
      }
      &:active {
        background: ${(props) => props.theme.color.gray30};
      }
    }
  }
`;

const FooterLegal = styled.div`
  margin: ${(props) => props.theme.style.footerLegalMargin};
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    margin-top: 5px;
    display: flex;
    cursor: pointer;
  }
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    justify-content: start;
  }
`;

const FooterContentWrapper = styled.div`
  display: ${(props) => props.theme.style.footerContentDisplay};
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    flex-direction: row;
  }
`;

function Footer({ options, data, languagePaths }: FooterProps) {
  const { isWhiteLabel, theme } = useWhiteLabel();
  const router = useRouter();
  const locale = router.locale && router.locale !== 'default' ? router.locale : DANSK.key;
  const windowSize = useGetWindowSize();
  const isMobile = isBelowBreakpoint(windowSize?.width || 0, 'lg');

  const FooterLinksData = [
    {
      href: data?.FacebookLink,
      title: 'Facebook',
      icon: faFacebook,
    },
    {
      href: data?.InstagramLink,
      title: 'Instagram',
      icon: faInstagram,
    },
    {
      href: data?.LinkedinLink,
      title: 'LinkedIn',
      icon: faLinkedin,
    },
    {
      href: data?.YoutubeLink,
      title: 'YouTube',
      icon: faYoutube,
    },
  ];

  const getFooterContent = () => {
    if (isWhiteLabel) {
      return (
        <>
          <FooterLinks>
            {theme.localization[locale].footerItems.map((item, index) => (
              <li key={index}>
                <Link href={item.url}>{item.text}</Link>
              </li>
            ))}
            <li>
              <LanguageSelect languagePaths={languagePaths} direction={isMobile ? 'down' : 'up'} />
            </li>
          </FooterLinks>
        </>
      );
    }

    return (
      <>
        <FooterLinks>
          {data?.Links.map((item, index) => {
            return <CMSLinkItem key={index} link={item} />;
          })}
          <li>
            <LanguageSelect languagePaths={languagePaths} />
          </li>
        </FooterLinks>
        <FooterSocial>
          {FooterLinksData.map((item, index) => (
            <li key={index}>
              <a href={item.href} target="_blank" rel="noreferrer" title={item.title}>
                <FontAwesomeIcon icon={item.icon} />
              </a>
            </li>
          ))}
        </FooterSocial>
      </>
    );
  };

  return (
    <FooterWrapper options={options}>
      <Container>
        <FooterContentWrapper>
          <FooterContent>{getFooterContent()}</FooterContent>
          <FooterLegal>
            {!isWhiteLabel ? (
              <Text>{data?.LegalText}</Text>
            ) : (
              <Link href={`https://propstep.com/${locale}`} passHref>
                <a>
                  <PoweredByPropstepLogo fill={theme.color.footerTextColor} />
                </a>
              </Link>
            )}
          </FooterLegal>
        </FooterContentWrapper>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
