import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { PopoverMenu } from 'legacy-ui';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { useTranslatedRoutes } from '../hooks/useTranslatedRoutes';
import { LanguageKey, ResourceLanguagePath } from '../types/common';
import { removeEmptyValues } from '../utils/common';
import { findLanguageByKey, LANGUAGES } from '../utils/languages';
import { Text } from 'legacy-ui';
import { useTranslation } from 'next-i18next';

const PopoverMenuWrapper = styled.div<{ ref?: any }>`
  position: relative;
`;

interface LanguageSelectProps {
  languagePaths?: ResourceLanguagePath[];
  direction?: 'up' | 'down';
}

const LanguageSelect = ({ languagePaths, direction }: LanguageSelectProps) => {
  const router = useRouter();
  const { locale, query } = router;
  const queryWithoutSlug = removeEmptyValues({ ...query, slug: '' });
  const [showLanguageSelection, setShowLanguageSelection] = useState(false);
  const routes = useTranslatedRoutes(languagePaths);
  const languages = LANGUAGES.filter((language) => routes.some((route) => route.locale === language.key));
  const ref = useRef<HTMLLIElement>(null);
  useOnClickOutside(ref, setShowLanguageSelection);
  const currentLanguage = findLanguageByKey(locale as LanguageKey)!;
  const { i18n } = useTranslation();

  const handleLanguageChange = async (languageKey: LanguageKey) => {
    setShowLanguageSelection(false);
    await i18n.changeLanguage(languageKey);
  };

  return (
    <PopoverMenuWrapper ref={ref}>
      <button onClick={() => setShowLanguageSelection(!showLanguageSelection)}>
        <FontAwesomeIcon icon={faGlobe} />
        <Text>{currentLanguage.description}</Text>
        <FontAwesomeIcon icon={faAngleDown} />
      </button>
      {showLanguageSelection && (
        <PopoverMenu position="mobileCenter" direction={direction}>
          {languages.map((language, index) => {
            const route = routes.find((route) => route.locale === language.key)!;
            return (
              <li key={index}>
                <Link href={{ pathname: route.route, query: queryWithoutSlug }} locale={language.key} passHref>
                  <a onClick={() => handleLanguageChange(language.key)}>{language.description}</a>
                </Link>
              </li>
            );
          })}
        </PopoverMenu>
      )}
    </PopoverMenuWrapper>
  );
};

export default LanguageSelect;
