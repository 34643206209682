export const CURRENCY_DKK = 'DKK';

export function formatAsCurrency(value: number, unit: string | undefined, currency: string = CURRENCY_DKK, locale: string = 'da-DK'): string {
  const valueAsString = (value || 0).toString();

  if (unit === 'cents') {
    return (Number(valueAsString) / 100).toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  return `${(value || 0).toLocaleString(locale)} ${currency}`;
}

export function formatToCents(value: number): number {
  return Math.round(value * 100);
}

export function formatCurrencyValue(value: number, locale: string = 'da-DK'): string {
  return value.toLocaleString(locale);
}
