import Link from 'next/link';
import { useRouter } from 'next/router';
import { LinkCMSResult } from '../types/cms/generalTypes';
import { LanguageKey } from '../types/common';
import { getRoute } from '../utils/languages';
import { PopoverMenuSeparator } from 'legacy-ui';

interface CMSLinkItemProps {
  link?: LinkCMSResult;
}

const CMSLinkItem = ({ link }: CMSLinkItemProps) => {
  const { locale } = useRouter();
  if (!link) {
    return null;
  }
  if (link.Link === '#') {
    return <PopoverMenuSeparator />;
  }
  return (
    <li>
      {link.External ? <a href={link.Link}>{link.Label}</a> : <Link href={getRoute(link.Link, locale as LanguageKey) || ''}>{link.Label}</Link>}
    </li>
  );
};

export default CMSLinkItem;
