import { faBars, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import logoWhite from '../../assets/logo-white.svg';
import CMSLinkItem from '../../components/CMSLinkItem';
import Login from '../../components/Login';
import { PopoverMenu, PopoverMenuMobile } from 'legacy-ui';
import UserMenu from '../../components/UserMenu';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { userState } from '../../state/userState';
import { HeaderCMSResult } from '../../types/cms/headerTypes';
import { LanguageKey } from '../../types/common';
import { loadFromApi } from '../../utils/http';
import { getSearchLink } from '../../utils/links';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import { useTranslation } from 'next-i18next';
import { useIsMobile } from 'hooks/useIsMobile';

export type HeaderOptions = 'white' | undefined;
interface HeaderProps {
  options?: HeaderOptions;
  data?: HeaderCMSResult;
  isWhiteLabel?: boolean;
  isGrid?: boolean;
}

const HeaderWrapper = styled.header`
  padding: ${(props) => props.theme.style.headerPadding};
  background: ${(props) => props.theme.color.headerBackgroundColor};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`;

const HeaderInner = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    height: 96px;
  }
`;

const HeaderLogo = styled.div`
  a {
    transition: ${(props) => props.theme.style.headerLogoTransition};
    width: ${(props) => props.theme.style.headerLogoWidthMobile};
    height: ${(props) => props.theme.style.headerLogoHeightMobile};
    margin: ${(props) => props.theme.style.headerLogoMarginMobile};
    display: block;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      width: ${(props) => props.theme.style.headerLogoWidthDesktop};
      height: ${(props) => props.theme.style.headerLogoHeightDesktop};
      margin: ${(props) => props.theme.style.headerLogoMargin};
    }
    &:hover {
      opacity: ${(props) => props.theme.style.headerLogoHoverOpacity};
    }
  }
`;

const HeaderNav = styled.ul<HeaderProps>`
  display: flex;
  align-items: center;
  > li {
    margin-left: 20px;
    position: relative;

    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      margin-left: 40px;
    }
    > a,
    > button,
    > div > button {
      transition: ${(props) => props.theme.style.headerItemTransition};
      color: ${(props) => props.theme.color.headerTextColor};
      cursor: pointer;
      font-weight: 500;
      &:hover {
        color: ${(props) => props.theme.color.headerTextColorHover};
      }
      svg {
        margin-right: 8px;
      }
      ${(props) => {
        const { isWhiteLabel, options } = props;
        if (!isWhiteLabel && options === 'white') {
          return `
            color: white;
            &:hover {
              color: rgba(255,255,255,0.8);
            }
          `;
        }
      }}
    }
    > button,
    > div > button {
      background: transparent;
    }
  }
`;

function Header({ options, data, isGrid }: HeaderProps) {
  const [showPopover, setShowPopover] = useState(false);
  const [{ status, user }, setUser] = useAtom(userState);
  const { locale } = useRouter();
  const { isWhiteLabel, urls, theme, restrictedPages } = useWhiteLabel();
  const { logo } = theme;
  const { t } = useTranslation('general');
  const ref = useRef<HTMLLIElement>(null);
  const isMobile = useIsMobile();

  useOnClickOutside(ref, setShowPopover);

  useEffect(() => {
    (async () => {
      setUser({ status: 'uninitialized' });
      const user = await loadFromApi('user/open');
      setUser({ status: 'loaded', user });
    })();
  }, [setUser]);

  const renderUserMenu = () => status !== 'uninitialized' && (user ? <UserMenu data={data?.UserMenu} /> : <Login data={data?.Login} />);

  function renderNavigationBar() {
    if (isWhiteLabel) {
      const searchPageAllowed = !restrictedPages.includes('search');
      return (
        <HeaderNav options={options} isWhiteLabel={isWhiteLabel}>
          {searchPageAllowed && (
            <li>
              <Link href={getSearchLink(locale as LanguageKey)} passHref>
                <a>
                  <FontAwesomeIcon icon={faSearch} />
                  {isMobile ? t('search') : data?.SearchLabel}
                </a>
              </Link>
            </li>
          )}
          <li>{renderUserMenu()}</li>
        </HeaderNav>
      );
    }
    return (
      <HeaderNav options={options} isWhiteLabel={isWhiteLabel}>
        {!isMobile && (
          <li>
            <Link href={getSearchLink(locale as LanguageKey)} passHref>
              <a>
                <FontAwesomeIcon icon={faSearch} />
                {data?.SearchLabel}
              </a>
            </Link>
          </li>
        )}
        <li ref={ref}>
          <button onClick={() => setShowPopover((prev) => !prev)}>
            <FontAwesomeIcon icon={faBars} />
            {data?.Menu.Label}
          </button>
          {showPopover && (
            <PopoverMenu>
              <PopoverMenuMobile>
                <Link href={getSearchLink(locale as LanguageKey)} passHref>
                  <a>{data?.SearchLabel}</a>
                </Link>
              </PopoverMenuMobile>
              {data?.Menu.Links.map((link) => <CMSLinkItem link={link} key={link.id} />)}
            </PopoverMenu>
          )}
        </li>
        <li>{renderUserMenu()}</li>
      </HeaderNav>
    );
  }

  return (
    <HeaderWrapper>
      <div
        className={
          isGrid
            ? 'relative my-0 mx-auto py-0 px-4 min-[1200px]:max-w-[1200px] min-[1536px]:max-w-[1480px] min-[1760px]:max-w-[1770px] min-[2100px]:max-w-[2050px] w-full'
            : 'relative max-w-[1200px] my-0 mx-auto py-0 px-4'
        }
      >
        <HeaderInner>
          <HeaderLogo>
            <Link href={isWhiteLabel ? logo.linkUrl ?? urls.webFront : '/'} passHref>
              <a>
                <Image src={!isWhiteLabel && options === 'white' ? logoWhite : logo.src} alt={logo.alt} width={logo.width} height={logo.height} />
              </a>
            </Link>
          </HeaderLogo>
          {renderNavigationBar()}
        </HeaderInner>
      </div>
    </HeaderWrapper>
  );
}

export default Header;
