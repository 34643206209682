import { useEffect, useState } from 'react';
import { useGetWindowSize } from './useGetWindowSize';

export function useIsMobile() {
  const windowSize = useGetWindowSize();

  if (!windowSize) {
    return false;
  }

  return windowSize.width < 768;
}
