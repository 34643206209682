const PoweredByPropstepLogo = ({ fill }: any) => {
  return (
    <svg width="214" height="18" viewBox="0 0 214 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1338_2)">
        <path
          d="M130.139 17.471V3.01512H131.856L131.978 4.31616H132.101C132.714 3.34554 133.919 2.78796 135.329 2.78796C138.067 2.78796 140.09 5.08025 140.09 8.17795C140.09 11.2756 138.067 13.5679 135.329 13.5679C133.981 13.5679 132.836 13.0516 132.203 12.143H132.08V17.471H130.139ZM135.043 11.7299C136.841 11.7299 138.067 10.2844 138.067 8.17795C138.067 6.07152 136.841 4.62593 135.043 4.62593C133.245 4.62593 132.08 6.03021 132.08 8.17795C132.08 10.3257 133.245 11.7299 135.043 11.7299Z"
          fill={fill}
        />
        <path
          d="M142.71 13.3409V3.01515H144.427L144.529 4.37814H144.651C145.203 3.44883 146.327 2.89124 147.655 2.89124C147.9 2.89124 148.125 2.9119 148.288 2.9532V4.93573C148.023 4.89442 147.921 4.89442 147.635 4.89442C145.734 4.89442 144.651 6.1748 144.651 8.4258V13.3409H142.71Z"
          fill={fill}
        />
        <path
          d="M154.815 13.5679C151.832 13.5679 149.584 11.2343 149.584 8.17795C149.584 5.12156 151.832 2.78796 154.815 2.78796C157.798 2.78796 160.046 5.12156 160.046 8.17795C160.046 11.2343 157.798 13.5679 154.815 13.5679ZM154.815 11.7299C156.674 11.7299 158.023 10.2224 158.023 8.17795C158.023 6.13347 156.674 4.62593 154.815 4.62593C152.955 4.62593 151.607 6.13347 151.607 8.17795C151.607 10.2224 152.955 11.7299 154.815 11.7299Z"
          fill={fill}
        />
        <path
          d="M162.664 17.471V3.01512H164.381L164.503 4.31616H164.626C165.239 3.34554 166.444 2.78796 167.854 2.78796C170.592 2.78796 172.615 5.08025 172.615 8.17795C172.615 11.2756 170.592 13.5679 167.854 13.5679C166.506 13.5679 165.362 13.0516 164.728 12.143H164.605V17.471H162.664ZM167.568 11.7299C169.366 11.7299 170.592 10.2844 170.592 8.17795C170.592 6.07152 169.366 4.62593 167.568 4.62593C165.77 4.62593 164.605 6.03021 164.605 8.17795C164.605 10.3257 165.77 11.7299 167.568 11.7299Z"
          fill={fill}
        />
        <path
          d="M178.714 13.5679C176.344 13.5679 174.648 12.143 174.586 10.0985H176.609C176.732 11.1517 177.57 11.8126 178.734 11.8126C179.919 11.8126 180.594 11.3995 180.594 10.6148C180.594 9.89201 180.226 9.52029 179.225 9.21052L177.672 8.75619C175.833 8.21925 174.913 7.22799 174.913 5.7824C174.913 4.04769 176.466 2.78796 178.591 2.78796C180.655 2.78796 182.228 4.04769 182.351 5.80305H180.328C180.185 5.0183 179.449 4.48137 178.53 4.48137C177.59 4.48137 176.936 5.03895 176.936 5.7824C176.936 6.42259 177.324 6.77366 178.448 7.08343L180.001 7.51711C181.738 8.01274 182.617 9.004 182.617 10.4909C182.617 12.3082 181.043 13.5679 178.714 13.5679Z"
          fill={fill}
        />
        <path
          d="M188.96 13.5679C187.04 13.5679 185.834 12.5353 185.834 10.5322V4.77045H183.852V3.01509H184.261C185.425 3.01509 185.916 2.3749 185.998 1.03257L186.059 0H187.775V3.01509H190.186V4.77045H187.775V10.3876C187.775 11.255 188.327 11.8126 189.165 11.8126C189.43 11.8126 189.778 11.7506 190.043 11.668L190.35 13.2994C189.962 13.4647 189.471 13.5679 188.96 13.5679Z"
          fill={fill}
        />
        <path
          d="M196.736 13.5679C193.712 13.5679 191.607 11.3582 191.607 8.17795C191.607 5.12156 193.814 2.78796 196.715 2.78796C199.494 2.78796 201.435 4.83244 201.435 7.72362C201.435 8.1573 201.415 8.38446 201.374 8.67358H193.651C193.814 10.5528 194.979 11.7299 196.715 11.7299C197.901 11.7299 199.004 11.1104 199.29 10.2844H201.313C200.945 12.2049 199.045 13.5679 196.736 13.5679ZM193.732 7.08343H199.351C199.229 5.61719 198.207 4.60527 196.756 4.60527C195.265 4.60527 194.08 5.61719 193.732 7.08343Z"
          fill={fill}
        />
        <path
          d="M204.049 17.471V3.01512H205.766L205.888 4.31616H206.011C206.624 3.34554 207.829 2.78796 209.239 2.78796C211.977 2.78796 214 5.08025 214 8.17795C214 11.2756 211.977 13.5679 209.239 13.5679C207.891 13.5679 206.746 13.0516 206.113 12.143H205.99V17.471H204.049ZM208.953 11.7299C210.751 11.7299 211.977 10.2844 211.977 8.17795C211.977 6.07152 210.751 4.62593 208.953 4.62593C207.155 4.62593 205.99 6.03021 205.99 8.17795C205.99 10.3257 207.155 11.7299 208.953 11.7299Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.415 17.471C119.24 17.471 123.151 13.56 123.151 8.73551C123.151 3.91102 119.24 0 114.415 0C109.591 0 105.68 3.91102 105.68 8.73551C105.68 13.56 109.591 17.471 114.415 17.471ZM111.108 5.41643H117.728V7.87743L117.727 12.0357H115.266V7.87743H111.108V5.41643ZM111.092 9.51586H113.63V12.0543H111.092V9.51586Z"
          fill={fill}
        />
        <path
          d="M0 13.6246V0.863892H4.81269C7.26549 0.863892 8.97502 2.4681 8.97502 4.74682C8.97502 7.02553 7.26549 8.64797 4.81269 8.64797H1.8396V13.6246H0ZM1.8396 7.02553H4.64545C6.132 7.02553 7.09825 6.13227 7.09825 4.74682C7.09825 3.37959 6.132 2.48633 4.64545 2.48633H1.8396V7.02553Z"
          fill={fill}
        />
        <path
          d="M15.0098 13.8253C12.2969 13.8253 10.2529 11.7653 10.2529 9.06728C10.2529 6.36929 12.2969 4.30933 15.0098 4.30933C17.7227 4.30933 19.7667 6.36929 19.7667 9.06728C19.7667 11.7653 17.7227 13.8253 15.0098 13.8253ZM15.0098 12.2028C16.7007 12.2028 17.9271 10.8721 17.9271 9.06728C17.9271 7.26254 16.7007 5.93178 15.0098 5.93178C13.3189 5.93178 12.0925 7.26254 12.0925 9.06728C12.0925 10.8721 13.3189 12.2028 15.0098 12.2028Z"
          fill={fill}
        />
        <path
          d="M23.6049 13.6246L21.022 4.50977H22.7316L24.5898 11.3459H24.7012L26.4665 5.60355H28.3433L30.1271 11.3459H30.2386L32.0782 4.50977H33.7878L31.2049 13.6246H29.3653L27.4699 7.66351H27.3584L25.4445 13.6246H23.6049Z"
          fill={fill}
        />
        <path
          d="M39.705 13.8253C36.9549 13.8253 35.041 11.8747 35.041 9.06728C35.041 6.36929 37.0478 4.30933 39.6865 4.30933C42.2136 4.30933 43.9789 6.11407 43.9789 8.66623C43.9789 9.04905 43.9603 9.24958 43.9231 9.5048H36.8992C37.0478 11.1637 38.107 12.2028 39.6865 12.2028C40.7642 12.2028 41.7676 11.6559 42.0278 10.9268H43.8674C43.5329 12.6221 41.8048 13.8253 39.705 13.8253ZM36.9735 8.10111H42.0835C41.972 6.8068 41.0429 5.91355 39.7236 5.91355C38.3672 5.91355 37.2894 6.8068 36.9735 8.10111Z"
          fill={fill}
        />
        <path
          d="M46.3555 13.6246V4.50977H47.9164L48.0093 5.71293H48.1208C48.6225 4.89259 49.6445 4.40039 50.8523 4.40039C51.0753 4.40039 51.2797 4.41862 51.4284 4.45508V6.20513C51.1868 6.16867 51.0939 6.16867 50.8338 6.16867C49.1056 6.16867 48.1208 7.29891 48.1208 9.28595V13.6246H46.3555Z"
          fill={fill}
        />
        <path
          d="M57.2705 13.8253C54.5204 13.8253 52.6064 11.8747 52.6064 9.06728C52.6064 6.36929 54.6133 4.30933 57.2519 4.30933C59.779 4.30933 61.5443 6.11407 61.5443 8.66623C61.5443 9.04905 61.5257 9.24958 61.4885 9.5048H54.4646C54.6133 11.1637 55.6724 12.2028 57.2519 12.2028C58.3296 12.2028 59.3331 11.6559 59.5932 10.9268H61.4328C61.0983 12.6221 59.3702 13.8253 57.2705 13.8253ZM54.5389 8.10111H59.6489C59.5375 6.8068 58.6084 5.91355 57.2891 5.91355C55.9326 5.91355 54.8548 6.8068 54.5389 8.10111Z"
          fill={fill}
        />
        <path
          d="M67.8417 13.8252C65.3518 13.8252 63.5122 11.8017 63.5122 9.06722C63.5122 6.33277 65.3518 4.30927 67.8417 4.30927C69.0681 4.30927 70.1087 4.76502 70.6848 5.56712H70.7962V0.499268H72.5615V13.6246H71.0192L70.9077 12.4762H70.7962C70.2202 13.333 69.1239 13.8252 67.8417 13.8252ZM68.1019 12.2027C69.7371 12.2027 70.7962 10.9631 70.7962 9.06722C70.7962 7.17134 69.7371 5.93172 68.1019 5.93172C66.4667 5.93172 65.3518 7.20779 65.3518 9.06722C65.3518 10.9267 66.4667 12.2027 68.1019 12.2027Z"
          fill={fill}
        />
        <path
          d="M84.8999 13.8252C83.6178 13.8252 82.5215 13.333 81.964 12.4762H81.8525L81.741 13.6246H80.1802V0.499268H81.9454V5.56712H82.0569C82.633 4.76502 83.6735 4.30927 84.8999 4.30927C87.3899 4.30927 89.2295 6.33277 89.2295 9.06722C89.2295 11.8017 87.3899 13.8252 84.8999 13.8252ZM84.6398 12.2027C86.275 12.2027 87.3899 10.9267 87.3899 9.06722C87.3899 7.20779 86.275 5.93172 84.6398 5.93172C83.0046 5.93172 81.9454 7.17134 81.9454 9.06722C81.9454 10.9631 83.0046 12.2027 84.6398 12.2027Z"
          fill={fill}
        />
        <path
          d="M92.3854 17.4711C91.7536 17.4711 91.1776 17.3435 90.6945 17.0883L91.2333 15.7393C91.605 15.9034 91.9023 15.958 92.3111 15.958C93.1101 15.958 93.7419 15.5388 94.0392 14.6455L94.3922 13.6246H93.8534L90.3042 4.50977H92.2368L94.9311 11.8564H95.0426L97.4397 4.50977H99.335L95.7301 15.0101C95.1913 16.5779 93.9277 17.4711 92.3854 17.4711Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1338_2">
          <rect width="214" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PoweredByPropstepLogo;
