import { validate } from 'email-validator';
import { useRouter } from 'next/router';
import { FormEvent, useState } from 'react';
import { ErrorAPIResult } from '../../types/api/commonTypes';
import { HeaderLoginCMSResult } from '../../types/cms/headerTypes';
import { LoginModalStatus } from '../../types/common';
import { track } from '../../utils/analytics';
import { loadFromApi } from '../../utils/http';
import { Button } from 'legacy-ui';
import { Checkbox } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import { ModalButtons, ModalCheckbox, ModalHeader, ModalTextField } from 'legacy-ui';
import { Text } from 'legacy-ui';
import { TextField } from 'legacy-ui';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import useDataLayer from '../../hooks/useDataLayer';

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  acceptTerms: boolean;
}

const EMPTY_USER: UserData = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  acceptTerms: false,
};

type InputKey = keyof UserData;

type Errors = {
  [key in InputKey]?: string;
};

interface CreateUserProps {
  data?: HeaderLoginCMSResult;
  email: string;
  setEmail: (email: string) => void;
  setStatus: (status: LoginModalStatus) => void;
}

const CreateUser = ({ data, email, setEmail, setStatus }: CreateUserProps) => {
  const { locale } = useRouter();
  const [userData, setUserData] = useState<UserData>({ ...EMPTY_USER, email });
  const [errors, setErrors] = useState<Errors>({});
  const { hostname: whiteLabelConfigHostname, localization } = useWhiteLabel();
  const { push, getAttributionData } = useDataLayer();
  function validateOnChange(key: InputKey, isSet: boolean) {
    if (isSet) {
      setErrors({ ...errors, [key]: '' });
    }
  }

  async function handleCreateUser(event: FormEvent) {
    event.preventDefault();
    const { firstName, lastName, email, confirmEmail, acceptTerms } = userData;

    const requiredErrors: Errors = {
      firstName: firstName ? '' : data?.InputFirstNameErrorRequired,
      lastName: lastName ? '' : data?.InputLastNameErrorRequired,
      email: email ? '' : data?.InputEmailErrorRequired,
      confirmEmail: confirmEmail ? '' : data?.InputConfirmEmailErrorRequired,
      acceptTerms: acceptTerms ? '' : data?.CheckoxAcceptTermsErrorRequired,
    };
    if (Object.keys(requiredErrors).some((key) => (requiredErrors as { [key: string]: string })[key])) {
      setErrors(requiredErrors);
      return;
    }

    if (!validate(email)) {
      setErrors({ email: data?.InputEmailErrorValid });
      return;
    }
    if (!validate(confirmEmail)) {
      setErrors({ confirmEmail: data?.InputConfirmEmailErrorValid });
      return;
    }
    if (email !== confirmEmail) {
      setErrors({ confirmEmail: data?.InputConfirmEmailErrorMatch });
      return;
    }
    setErrors({});
    try {
      await loadFromApi('auth/signup', {
        attributionData: getAttributionData(),
        firstName,
        lastName,
        email,
        language: locale?.substring(0, 2),
        whiteLabelConfigHostname,
      });
      setEmail(email);
      setStatus('enterCode');

      await track('User Signup', { firstName, lastName, email });
      push({ event: 'User Signup', firstName, lastName, email });
    } catch (error) {
      const errorResult: ErrorAPIResult = JSON.parse((error as Error).message);
      if (errorResult.message === 'NOT_AVAILABLE_EMAIL') {
        setErrors({ email: data?.InputEmailErrorTaken });
        return;
      }
      setErrors({ acceptTerms: data?.GeneralErrorMessage });
    }
  }
  return (
    <>
      <ModalHeader>
        <Heading size="s" tag="h3">
          {data?.SignUpHeadline}
        </Heading>
        {data?.SignUpDescription && <Text>{data?.SignUpDescription}</Text>}
      </ModalHeader>
      <form onSubmit={handleCreateUser} noValidate id="form">
        <ModalTextField>
          <TextField
            autoFocus
            type="text"
            id="firstName"
            value={userData.firstName}
            onChange={(event) => {
              const { value } = event.target;
              validateOnChange('firstName', value.length > 0);
              setUserData({ ...userData, firstName: value });
            }}
            label={data?.InputFirstNameLabel}
            maxLength={50}
            autoComplete="given-name"
            error={errors.firstName}
          />
        </ModalTextField>
        <ModalTextField>
          <TextField
            type="text"
            id="lastName"
            value={userData.lastName}
            onChange={(event) => {
              const { value } = event.target;
              validateOnChange('lastName', value.length > 0);
              setUserData({ ...userData, lastName: value });
            }}
            label={data?.InputLastNameLabel}
            maxLength={50}
            autoComplete="family-name"
            error={errors.lastName}
          />
        </ModalTextField>
        <ModalTextField>
          <TextField
            type="email"
            id="email"
            value={userData.email}
            onChange={(event) => {
              const { value } = event.target;
              validateOnChange('email', value.length > 0);
              setUserData({ ...userData, email: value });
            }}
            label={data?.InputEmailLabel}
            maxLength={100}
            autoComplete="email"
            error={errors.email}
          />
        </ModalTextField>
        <ModalTextField>
          <TextField
            type="email"
            id="confirmEmail"
            value={userData.confirmEmail}
            onChange={(event) => {
              const { value } = event.target;
              validateOnChange('confirmEmail', value.length > 0);
              setUserData({ ...userData, confirmEmail: value });
            }}
            label={data?.InputConfirmEmailLabel}
            maxLength={100}
            autoComplete="email"
            error={errors.confirmEmail}
          />
        </ModalTextField>
        <ModalCheckbox>
          <Checkbox
            id="acceptTerms"
            checked={userData.acceptTerms}
            onChange={() => {
              validateOnChange('acceptTerms', !userData.acceptTerms);
              setUserData({ ...userData, acceptTerms: !userData.acceptTerms });
            }}
            error={errors.acceptTerms}
          >
            {locale && localization[locale].terms}
          </Checkbox>
        </ModalCheckbox>
        <ModalButtons>
          <div>
            <Button fullWidth color="primary" type="submit">
              {data?.SignUpButton}
            </Button>
          </div>
        </ModalButtons>
      </form>
    </>
  );
};

export default CreateUser;
