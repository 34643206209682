import { useAtom } from 'jotai';
import { FormEvent, useEffect, useState } from 'react';
import { userState } from '../../state/userState';
import { ErrorAPIResult } from '../../types/api/commonTypes';
import { UserAPIResult } from '../../types/api/userTypes';
import { HeaderLoginCMSResult } from '../../types/cms/headerTypes';
import { LoginModalStatus } from '../../types/common';
import { identify, track } from '../../utils/analytics';
import { loadFromApi } from '../../utils/http';
import { Button } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import { ModalButtons, ModalHeader, ModalText, ModalTextField } from 'legacy-ui';
import { Text } from 'legacy-ui';
import { TextField } from 'legacy-ui';
import { useWhiteLabel } from '../../providers/WhiteLabelProvider';
import useDataLayer from '../../hooks/useDataLayer';

const ONE_SECOND = 1000;
const LOGIN_CODE_LENGTH = 6;
const INITIAL_TIME = 59;

interface EnterCodeProps {
  data?: HeaderLoginCMSResult;
  email: string;
  setStatus: (status: LoginModalStatus) => void;
  closeModal: () => void;
}

const EnterCode = ({ data, email, setStatus, closeModal }: EnterCodeProps) => {
  const [loginCode, setLoginCode] = useState('');
  const [error, setError] = useState('');
  const [{ status, onLogin }, setUser] = useAtom(userState);
  const { hostname: whiteLabelConfigHostname } = useWhiteLabel();

  const [codeTimeout, setCodeTimeout] = useState(0);

  const { push } = useDataLayer();

  useEffect(() => {
    codeTimeout > 0 &&
      setTimeout(() => {
        setCodeTimeout((value) => value - 1);
      }, ONE_SECOND);
  }, [codeTimeout]);

  async function handleEnterCode(event: FormEvent) {
    event.preventDefault();
    if (loginCode.length !== LOGIN_CODE_LENGTH) {
      setError(`${data?.InputOneTimeCodeErrorRequired}`);
      return;
    }
    setUser({ status: 'loading', showLoginPopup: true });
    try {
      const result: UserAPIResult = await loadFromApi('auth/code', { email, loginCode });
      setUser({ status: 'loaded', user: result, showLoginPopup: false, onLogin: undefined });
      onLogin && onLogin(result);

      await track('User Login');

      push({
        event: 'User Login',
        createdAt: result.addedOn,
      });

      await identify(result);

      closeModal();
    } catch (error) {
      const errorResult: ErrorAPIResult = JSON.parse((error as Error).message);
      if (errorResult.message === 'INVALID_LOGIN_CODE') {
        setError(`${data?.InputOneTimeCodeErrorInvalid}`);
        setUser({ status: 'error', showLoginPopup: true, onLogin });
        return;
      }
      setError('Something went wrong');
      setUser({ status: 'error', showLoginPopup: true, onLogin });
    }
  }

  function handleSwitchEmail() {
    setStatus('enterEmail');
  }

  async function requestNewCode() {
    await loadFromApi('auth/login', { email, firstRequest: true, whiteLabelConfigHostname });
    setCodeTimeout(INITIAL_TIME);
  }

  return (
    <>
      <ModalHeader>
        <Heading size="s" tag="h3">
          {data?.ConfirmHeadline}
        </Heading>
        <Text>{data?.ConfirmDescription}</Text>
      </ModalHeader>
      <form onSubmit={handleEnterCode} noValidate>
        <ModalTextField>
          <TextField
            autoFocus
            type="text"
            placeholder={data?.InputOneTimeCodePlaceholder}
            maxLength={LOGIN_CODE_LENGTH}
            id="oneTimeCode"
            value={loginCode}
            onChange={(event) => {
              const { value } = event.target;
              value && setError('');
              setLoginCode(value);
            }}
            label={data?.InputOneTimeCodeLabel}
            autoComplete="one-time-code"
            error={error}
          />
        </ModalTextField>
        <ModalButtons>
          <div>
            <Button fullWidth color="primary" type="submit" disabled={status === 'loading'}>
              {data?.ConfirmButtonPrimary}
            </Button>
          </div>
          <div>
            <Button fullWidth onClick={handleSwitchEmail}>
              {data?.ConfirmButtonSecondary}
            </Button>
          </div>
        </ModalButtons>
        <ModalText>
          <Text>
            {data?.ConfirmNoCodeText}
            <br />
            {codeTimeout > 0 ? (
              <span>
                {data?.ConfirmResendCodeText} {codeTimeout}s
              </span>
            ) : (
              <a onClick={requestNewCode}>{data?.ConfirmResendCodeLink}</a>
            )}
          </Text>
        </ModalText>
      </form>
    </>
  );
};

export default EnterCode;
