import { useEffect, useState } from 'react';

interface WindowSize {
  width: number;
  height: number;
}

export function useGetWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>();

  function handleWindowSizeChange() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return windowSize;
}
