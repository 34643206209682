import { LanguageKey } from '../types/common';
import { splitLocale } from './languages';
import { SPA_BASE_URL } from './constants';

type PathKey = 'applications';
const translatedPaths: { [key: string]: { [key in PathKey]: string } } = {
  da: {
    applications: 'anmodninger',
  },
  en: {
    applications: 'applications',
  },
};

export function getApplicationLink(applicationId: string, locale: LanguageKey, spaBaseUrl: string) {
  const { language } = splitLocale(locale);
  return `${spaBaseUrl}/${locale}/a/user/${translatedPaths[language].applications}/${applicationId || ''}`;
}

export function getWhiteLabelApplicationLink(applicationId: string, locale: LanguageKey, spaBaseUrl: string) {
  const { language } = splitLocale(locale);
  return `${spaBaseUrl}/${locale}/a/user/${translatedPaths[language].applications}/${applicationId || ''}`;
}

export function getApplicationLinkAsCompany(applicationId: string, companyId: string, locale: LanguageKey) {
  return `${SPA_BASE_URL}/${locale}/a/company/${companyId}/applications/${applicationId || ''}`;
}

export function getApplicationLinkAsAdmin(applicationId: string, locale: LanguageKey) {
  return `${SPA_BASE_URL}/${locale}/a/admin/applications/${applicationId || ''}`;
}

export function getApplicationsInboxForSpecificPropertyAsAdmin(propertyId: string, locale: LanguageKey) {
  return `${SPA_BASE_URL}/${locale}/a/admin/applications?propertyId=${propertyId || ''}`;
}

export function getApplicationsInboxForSpecificProperty(propertyId: string, locale: LanguageKey, companyId: string) {
  return `${SPA_BASE_URL}/${locale}/a/company/${companyId}/applications?propertyId=${propertyId || ''}`;
}

export function getCompanyDashboardLink(companyId: string, locale: LanguageKey, spaBaseUrl: string) {
  return `${spaBaseUrl}/${locale}/a/company/${companyId}/dashboard`;
}
