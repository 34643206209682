import { LanguageKey } from '../types/common';

const DEFAULT_PHONE_CODE = '45';

const suffixes: Omit<Record<LanguageKey, Partial<Record<Intl.LDMLPluralRule, string>>>, 'default'> = {
  'da-DK': {
    other: '.',
  },
  'en-DK': {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  },
};

export function numberToOrdinal(num: number, locale: LanguageKey): string {
  const realLocale = locale === 'default' ? 'da-DK' : locale;
  const ordinalRules = new Intl.PluralRules(realLocale, { type: 'ordinal' });
  const suffix = suffixes[realLocale]?.[ordinalRules.select(num)];
  return `${num}${suffix || ''}`;
}

export function splitPhoneNumber(phoneNumber?: string) {
  if (!phoneNumber?.length) {
    return {
      phoneCode: DEFAULT_PHONE_CODE,
      localPhoneNumber: '',
    };
  }
  const phoneNumberParts = phoneNumber.split(' ');
  if (phoneNumberParts.length !== 2) {
    return {
      phoneCode: DEFAULT_PHONE_CODE,
      localPhoneNumber: phoneNumber,
    };
  }
  const [phoneCode, localPhoneNumber] = phoneNumberParts;
  return {
    phoneCode: phoneCode.replace('+', ''),
    localPhoneNumber,
  };
}
