import { useRouter } from 'next/router';
import { LanguageKey, ResourceLanguagePath } from '../types/common';
import { getRoute } from '../utils/languages';

interface TranslatedRoute {
  locale: LanguageKey;
  route: string;
}

export function useTranslatedRoutes(languagePaths?: ResourceLanguagePath[]): TranslatedRoute[] {
  const { locales, pathname } = useRouter();
  const realLocales = (locales as LanguageKey[])?.filter((locale) => locale !== 'default');

  if (!languagePaths) {
    return realLocales?.map((locale) => ({ locale, route: getRoute(pathname, locale) })) || [];
  }
  return languagePaths.map((languagePath) => {
    const basicRoute = getRoute(pathname, languagePath.key, languagePaths);
    const route = languagePath.query ? basicRoute.replace(/\[.*\]/, languagePath.query.value) : basicRoute;
    return {
      locale: languagePath.key,
      route,
    };
  });
}
